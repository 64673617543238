// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-something-framed-js": () => import("./../src/pages/get-something-framed.js" /* webpackChunkName: "component---src-pages-get-something-framed-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minneapolis-mn-shadowbox-framing-js": () => import("./../src/pages/minneapolis-mn-shadowbox-framing.js" /* webpackChunkName: "component---src-pages-minneapolis-mn-shadowbox-framing-js" */),
  "component---src-pages-minnesota-custom-framing-services-js": () => import("./../src/pages/minnesota-custom-framing-services.js" /* webpackChunkName: "component---src-pages-minnesota-custom-framing-services-js" */)
}

